.ml-page {
  display: grid;
  grid-template-columns: 300px 1fr;
  background: url("../images/ml-page-bg.jpg") center/cover no-repeat;
  width: 100%;
  height: 100vh;

  &__main {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &--white {
      background-color: $white;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $border-color;
    padding-right: 300px;
    padding: 0 375px 0 70px;
    position: relative;
  }

  &__logo {
    display: flex;
    margin: 10px 0;

    img {
      height: 80px;
    }
  }

  &__body {
    flex: 1;
    overflow: hidden;
    padding: 30px 0;
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 0 375px 0 75px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__main--white &__footer {
    border-top: 1px solid $border-color;
  }

  &__main--white &__toggle span {
    background-color: #333;
  }

  @media (max-width: 1599px) {
    &__header {
      padding: 0 15px;
    }

    &__body {
      padding: 20px 0;
    }

    &__content {
      padding: 0 15px;
    }
  }

  @media (max-width: 1199px) {
    display: block;

    &__toggle {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }
}

.n-account {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    text-align: center;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    img {
      height: 30px;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-bottom: 10px;

    &__btn {
      img {
        height: 16px;
        margin-bottom: 8px;
      }
    }
  }
}
