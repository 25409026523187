.banner {
  width: 100%;
  min-height: 100vh;
  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 120px 0 20px;
    background: url("../images/banner-bg.jpg") center/cover no-repeat;
    background-position: 65% 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__title {
    font-size: 50px;
    font-weight: 700;
    color: $white;
    margin-bottom: 20px;
    max-width: 1000px;
  }

  &__desc {
    font-size: 18px;
    color: $white;
    margin-bottom: 40px;
    max-width: 1000px;
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    min-height: unset;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 45%;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 30px;
      margin-bottom: 16px;
    }

    &__desc {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 26px;
    }
  }
}
