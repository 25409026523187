.player {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  position: relative;
  z-index: 1;
  background-color: #000;

  &__header {
    padding: 15px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(#000, 0.9), transparent);
    color: $white;
    transition: 0.4s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  &:hover &__header {
    opacity: 1;
  }

  &__toggle {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
  }

  &__subtitle {
    font-weight: 400;
  }

  &__link {
    font-size: 16px;
    font-weight: 700;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__body {
    flex: 1;
    position: relative;
  }

  &__footer {
    display: flex;
    background-color: #2c2f37;
    padding: 0 15px;
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    height: 35px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 15px;
    line-height: 35px;
    background-color: #3c3c3c;
    border: 0;
    color: #ccc;
    margin: 0;
    cursor: pointer;
    position: relative;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    input {
      position: relative;
      z-index: -1;
      opacity: 0;
    }

    input:checked + .player__switch {
      background-color: #fff;

      &::before {
        margin-left: auto;
      }
    }
  }

  &__switch {
    display: inline-block;
    width: 30px;
    height: 16px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px $white;
    margin-left: -8px;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $white;
      background-color: #3c3c3c;
    }
  }

  @media (max-width: 991px) {
    &__header {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    &__btn {
      min-width: 35px;
      justify-content: center;
      padding: 0 10px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}

.md-menu {
  width: 320px;
  height: 100vh;
  overflow: auto;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: $white;
  transform: translateX(-100%);
  transition: 0.5s;

  &.show {
    transform: translateX(0);
    box-shadow: 0 1px 20px rgba(#000, 0.3);
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: auto;
  }

  &__link {
    display: block;
    background-color: $light;
    padding: 12px;
    font-size: 14px;
    font-weight: 700;
    color: #333;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }

  &__sub {
    padding: 0 12px;
  }

  &__sub-link {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 0;
    color: #333;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    &.active {
      color: $primary;
      text-decoration: none;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__time {
    margin-left: auto;
    color: #999;
    font-weight: 400;
  }
}

.md-comment {
  width: 320px;
  height: 100vh;
  overflow: auto;
  max-width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: $white;
  transform: translateX(100%);
  transition: 0.5s;

  &.show {
    transform: translateX(0);
    box-shadow: 0 1px 20px rgba(#000, 0.3);
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-bottom: 1px solid $border-color;
    position: relative;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    border: 0;
    // background: $light;
    background: 0;
    border-radius: 50%;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 24px;
      border-bottom: 2px solid #555;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__body {
    padding: 20px;
  }
}

.comment {
  & + & {
    border-top: 1px solid $border-color;
    margin-top: 10px;
    padding-top: 10px;
  }

  &__avatar {
    @include frame("circle");
    width: 32px;
    margin-right: 8px;
  }

  &__name {
    font-size: 14px;
    color: $primary;
    padding-right: 10px;
  }

  &__time {
    font-weight: 300;
    font-size: 12px;
    font-style: italic;
    color: #999;
  }

  &__remove {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #333;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      color: #000;
      text-decoration: none;
      background-color: $border-color;
    }
  }

  &__text {
    padding: 8px 0;
    font-weight: 400;
  }

  &__btn {
    display: inline-block;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__reply {
    display: none;
  }

  .comment {
    margin-left: 40px;

    &:first-child {
      margin-top: 8px;
    }
  }
}

#video-player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:focus {
    outline: 0;
  }

  .vjs-big-play-button {
    display: flex;
    top: 50%;
    left: 50%;
    width: 3em;
    height: 3em;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }

  &.vjs-playing .vjs-big-play-button {
    display: none;
  }

  .vjs-big-play-button .vjs-icon-placeholder::before {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vjs-control-bar {
    opacity: 1;
    // bottom: 20px;
  }

  .vjs-playback-rate {
    order: 1;
  }

  .vjs-fullscreen-control {
    order: 2;
  }

  video {
    outline: 0;

    &:focus {
      outline: 0;
    }
  }
}
