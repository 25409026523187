.n-video {
  position: relative;
  z-index: 1;
  padding-right: 15%;

  &__frame {
    @include frame("rect", 125%);
  }

  &__overlay {
    width: 65%;
    position: absolute;
    bottom: 10%;
    right: 0;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      transition: 0.3s;
    }

    &:hover::after {
      background-color: rgba(#000, 0.3);
    }
  }

  &__frame-2 {
    @include frame("rect", 86%);
  }

  &__icon {
    display: flex;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 0 0 10px rgba(#fff, 0.4);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}

.advice-form {
  max-width: 700px;
  margin: 0 auto 30px;

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    border: 2px solid $white;
    height: 50px;
    padding: 30px;
    color: $white;
    background: 0;

    &::placeholder {
      color: $white;
    }
  }

  @media (max-width: 767px) {
    .form-control {
      height: 48px;
      border-width: 1px;
      padding: 0 20px;
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 20px;

  &__btn {
    display: block;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: $warning;
    line-height: 58px;
    border: 1px solid $warning;
    border-radius: 4px;
    padding: 0 50px;
    text-align: center;
    @include shadow("xs");
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: $warning;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &--warning {
      border-color: $warning;
      background-color: $warning;
      color: $white;

      &:hover {
        color: $white;
        background-color: darken($warning, 5%);
      }
    }

    &--secondary {
      border-color: $secondary;
      background-color: $secondary;
      color: $white;

      &:hover {
        color: $white;
        background-color: darken($secondary, 5%);
      }
    }

    &--primary {
      border-color: $primary;
      background-color: $primary;
      color: $white;

      &:hover {
        color: $white;
        background-color: darken($primary, 5%);
      }
    }
  }
}
