.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row dense;

  &__col {
    &:nth-child(10n + 1) {
      grid-column: 3 / span 2;
      grid-row: span 2;
    }

    &:nth-child(10n + 6) {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;

    &__col {
      &:nth-child(10n + 1) {
        grid-column: span 2;
      }
    }
  }
}

.testimonial {
  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
  }
}

.school-achievement {
  &__frame {
    @include frame("rect", 80%);
  }

  &__icon {
    display: flex;
    width: 85px;
    height: 85px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $green-lighter;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 10px rgba(#000, 0.08);
  }

  &__number {
    font-size: 70px;
    color: $green-lighter;
    line-height: 1.2;
    text-transform: uppercase;

    small {
      font-size: 0.5em;
    }
  }

  @media (max-width: 991px) {
    &__icon {
      width: 80px;
      height: 80px;
      top: 0;
      left: 50%;
    }
  }
}

.sc-course {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 110%;
  }

  &__inner {
    width: 110%;
    height: 100%;
    border: 40px solid transparent;
    position: absolute;
    top: 0;
    right: 0;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border: 30px solid transparent;
      border-color: #25acde #25acde transparent transparent;
      border-radius: 50%;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }
  }

  &__tabs {
    position: static;

    .nav-item {
      display: block;
      width: calc(50% - 15px);
      height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 20;
      transform-origin: left;
      transform: rotate(-70deg);

      .nav-link {
        transform: translate(-50%, -50%) rotate(70deg);
      }

      &:nth-child(2) {
        transform: rotate(-35deg);

        .nav-link {
          transform: translate(-50%, -50%) rotate(35deg);
        }
      }

      &:nth-child(3) {
        transform: rotate(0);

        .nav-link {
          transform: translate(-50%, -50%) rotate(0);
        }
      }

      &:nth-child(4) {
        transform: rotate(35deg);

        .nav-link {
          transform: translate(-50%, -50%) rotate(-35deg);
        }
      }

      &:nth-child(5) {
        transform: rotate(70deg);

        .nav-link {
          transform: translate(-50%, -50%) rotate(-70deg);
        }
      }
    }

    .nav-link {
      width: 120px;
      padding: 0;
      border-radius: 50%;
      border: 1px solid $white;
      overflow: hidden;
      box-shadow: 0 1px 10px rgba(#000, 0.1);
      position: absolute;
      top: 100%;
      left: 100%;
      transform: translate(-50%, -50%);
    }
  }

  &__frame {
    @include frame("circle");
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    background-color: rgba(#f29705, 0.7);
    opacity: 0;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &__tabs .nav-link.active &__text {
    opacity: 1;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 30px;
    left: 4%;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__content {
    max-height: 100%;
    padding: 120px 120px 140px 8%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  @media (max-width: 1199px) {
    &::before,
    &::after {
      display: none;
    }

    &__inner {
      width: auto;
      height: auto;
      position: static;
      border: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &__tabs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-width: 500px;
      margin: -5px auto 16px;

      .nav-item {
        position: static !important;
        transform: unset !important;
        width: auto !important;
        height: auto !important;
        padding: 5px;
      }

      .nav-link {
        position: relative !important;
        top: auto;
        left: auto;
        transform: unset !important;
      }
    }

    &__title {
      position: static;
      text-align: center;
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__content {
      position: static;
      padding: 0;
      transform: unset;
    }
  }

  @media (max-width: 575px) {
    &__tabs {
      .nav-item {
        width: 33.33% !important;
      }

      .nav-link {
        width: auto;
      }
    }

    &__text {
      font-size: 16px;
    }
  }
}
