.footer {
  background-color: $blue-darker;
  padding: 40px 0 10px;

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 15px;
  }
}

.f-contact {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  color: $white;

  &__item {
    padding-left: 34px;
    position: relative;
    font-size: 14px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    a,
    a:hover {
      color: $white;
    }

    .fa {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $warning;
      color: $blue-darker;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.f-social {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border: 1px solid $white;
    border-radius: 50%;
    padding: 8px;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}
