.sp-grid {
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-gap: 30px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.sp-tabs {
  display: block;
  height: 100%;
  border-right: 1px solid $border-color;

  .nav-link {
    color: #333;
    border: 1px solid transparent;
    padding: 10px 15px;

    &:hover {
      color: #333;
      text-decoration: none;
    }

    &.active {
      position: relative;
      border: 1px solid $border-color;
      border-right: 0;
      position: relative;

      &::after {
        content: "";
        display: block;
        height: 100%;
        border-right: 1px solid $white;
        position: absolute;
        top: 0;
        left: 100%;
      }
    }
  }

  @media (max-width: 991px) {
    border: 1px solid $border-color;
    padding: 8px;
    border-radius: 4px;

    .nav-link {
      border-radius: 4px;

      &.active {
        border-right: 1px solid $border-color;
        background-color: $light;

        &::after {
          display: none;
        }
      }
    }
  }
}
