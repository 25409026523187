.recruit {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 16px;
  }

  &__social {
    margin-bottom: 16px;
  }

  &__content {
    h2 {
      font-size: 16px;
      font-weight: 700;
      color: $secondary;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        padding-left: 16px;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &::after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $secondary;
          position: absolute;
          top: 7px;
          left: 0;
        }
      }
    }
  }

  &__frame {
    display: block;
    align-self: stretch;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1599px) {
    &__title {
      font-size: 24px;
    }
  }

  @media (max-width: 575px) {
    &__frame {
      flex: 0 0 100%;

      img {
        position: static;
      }
    }
  }
}

.recruit-info {
  width: 330px;
  padding: 20px;
  background-color: $secondary;

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 20px;
  }

  &__item {
    color: $white;

    & + & {
      margin-top: 16px;
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      object-position: 50% 50%;
      margin-right: 14px;
      filter: brightness(0) invert(1);
    }
  }

  &__btn {
    display: block;
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center;
    color: $secondary;
    background-color: $white;
    margin-top: 20px;

    &:hover {
      color: $secondary;
      text-decoration: none;
    }
  }

  @media (max-width: 575px) {
    flex: 0 0 100%;
  }
}
