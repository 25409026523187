.as-menu {
  list-style: none;
  padding-left: 0;
  margin: -15px 0;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__link {
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 10px 15px;
    position: relative;
    color: #333;
    position: relative;
    left: 0;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
      left: 5px;
    }

    &::before {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      border: 1px solid #666;
      position: absolute;
      top: 16px;
      left: 0;
    }
  }
}

.post {
  margin-bottom: 30px;

  &-title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  &-info {
    margin-bottom: 16px;
  }

  &-sharing {
    margin-bottom: 16px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.post-tabs {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $secondary;
  margin-bottom: 20px;
  padding-bottom: 16px;

  .nav-item {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  .nav-link {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #333;
    padding: 0;

    &:hover {
      color: #333;
    }

    &.active {
      &,
      &:hover {
        color: $secondary;
      }
    }
  }
}
