.md-video {
  &__body {
    padding: 0;
    position: relative;
  }

  &__close {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    color: $white;
    background-color: $black;
    border: 2px solid $border-color;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(#000, 0.3);

    &::before,
    &::after {
      content: "";
      display: block;
      width: 14px;
      height: 2px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }
  }

  @media (min-width: 1199px) {
    .modal-dialog {
      max-width: 1020px;
    }
  }
}

.md-cart {
  &__content {
    border-radius: 20px;
    overflow: hidden;
  }

  &__header {
    background-color: $secondary;
    padding: 16px 50px;
  }

  &__body {
    padding: 30px 50px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__total {
    font-size: 16px;
    font-weight: 700;
    color: #c90101;
    margin-top: 8px;
    text-align: right;
  }

  &__btn {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px;
    color: $white;
    background-color: #c90101;
    border: 0;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken(#c90101, 5%);
    }
  }

  &__frame {
    @include frame("rect", 50%);
    width: 136px;
    margin-right: 10px;
  }

  &__name {
    font-size: 16px;
    font-weight: 700;
  }

  .text-danger {
    color: #c90101 !important;
  }

  @media (min-width: 992px) {
    .modal-dialog {
      max-width: 800px;
    }

    .table {
      td {
        border: 0;
        padding: 10px 0;

        &:not(:last-child) {
          padding-right: 20px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    &__content {
      border-radius: 4px;
    }

    &__header {
      padding: 8px 15px;
    }

    &__body {
      padding: 20px 15px;
    }

    &__frame {
      width: 40%;
    }

    .table {
      td {
        &:first-child {
          padding-left: 8px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    &__btn {
      font-size: 14px;
      padding: 0 12px;
    }
  }
}

.cart-step {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &__item {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    &:not(:last-child) {
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 5px;
        background-color: $white;
        position: absolute;
        z-index: -1;
        top: 17px;
        left: 50%;
      }
    }
  }

  &__number {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(#fff, 0.5);
    border-radius: 50%;
    background-color: $white;
    background-clip: padding-box;
    color: $secondary;
    font-size: 18px;
    font-weight: 700;
  }

  &__text {
    color: $white;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-top: 8px;
  }

  &__item.active &__number {
    background-color: $secondary;
    color: $white;
    border-color: #92d5ee;
  }

  @media (max-width: 575px) {
    &__text {
      font-size: 13px;
    }
  }
}

.md-signin {
  &__content {
    display: grid;
    grid-template-columns: 1fr 280px;
    border-radius: 20px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 40px 80px 10px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $secondary;
    border-radius: 0 20px 20px 0;
    padding: 50px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    position: relative;
    color: $secondary;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &::after {
      content: "";
      width: 30px;
      border-bottom: 2px solid #707070;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &.text-white::after {
      border-bottom-color: #fff;
    }
  }

  &__desc {
    line-height: 21px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__btn {
    display: inline-block;
    border: 1px solid $white;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    line-height: 33px;
    padding: 0 39px;
    border-radius: 18px;
    background: 0;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &--blue {
      background-color: $secondary;
      border-color: $secondary;
      color: $white;

      &:hover {
        background-color: darken($secondary, 5%);
      }
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }

  &__nav-item {
    display: inline-block;
    font-size: 12px;
    color: #999999;

    &:hover {
      color: $secondary;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 30px;
      position: relative;

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $secondary;
        position: absolute;
        top: 50%;
        right: -15px;
        transform: translate(50%, -50%);
      }
    }
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__social {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid $border-color;
    border-radius: 50%;
    color: #333;

    &:hover {
      color: $secondary;
      border-color: $secondary;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__label {
    display: inline-block;
    background-color: $white;
    position: absolute;
    color: $secondary;
    padding: 0 6px;
    font-size: 12px;
    top: 0;
    left: 10px;
    transform: translateY(-50%);
  }

  .form-control {
    padding-left: 16px;

    &:focus {
      border-color: $secondary;
    }
  }

  &__close {
    display: none;
  }

  @media (min-width: 992px) {
    .modal-dialog {
      max-width: 700px;
    }
  }

  @media (max-width: 991px) {
    &__content {
      display: block;
    }

    &__aside {
      display: none;
    }

    &__main {
      padding: 40px 30px 10px;
      position: relative;
    }

    &__close {
      display: inline-flex;
      border: 0;
      background: 0;
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 24px;
      color: #ccc;
    }
  }
}

.md-note {
  &__list {
    width: 100%;
    margin-top: 16px;
    max-height: 300px;
    overflow: auto;
    padding-right: 10px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $border-color;
    }
  }

  &__time {
    font-style: italic;
    font-weight: 300;
    color: #777;
    margin-bottom: 4px;
  }

  &__edit {
    display: none;
  }

  &__btn {
    display: none;
    margin-top: 4px;
  }

  &__item.edit &__edit {
    display: block;
    height: 100px;
  }

  &__item.edit &__btn {
    display: inline-block;
  }

  &__item.edit &__text {
    display: none;
  }
}
