.section {
  padding: 40px 0 20px;

  &__bg {
    @include img("bg");
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
  }

  &--bg {
    position: relative;
    z-index: 1;
  }

  &--bg &__title {
    color: $white;
  }

  &--secondary {
    background-color: $secondary;
  }

  &--secondary &__title {
    color: $white;
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }
  }
}

.section-2 {
  background-color: $white;
  border-radius: 20px;
  padding: 24px 24px 30px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #777;
    margin-bottom: 20px;
    padding-bottom: 16px;
  }

  @media (max-width: 1199px) {
    padding: 20px;
    border-radius: 10px;

    &__title {
      font-size: 20px;
    }
  }
}

.section-3 {
  padding: 50px 0 20px;

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
    }
  }
}

.section-4 {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &--transparent {
    color: $white;

    a,
    a:hover {
      color: $white;
    }
  }

  &--transparent &__title {
    margin-left: -75px;
  }

  @media (max-width: 1599px) {
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &--transparent &__title {
      margin-left: 0;
    }
  }
}

.aside {
  @include shadow("xs");
  padding: 14px;

  & + & {
    margin-top: 20px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 2px solid $secondary;
    margin-bottom: 15px;
    padding-bottom: 8px;
  }
}

.aside-2 {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: $white;
    text-align: center;
    padding: 16px;
    border-radius: 4px;
    background-color: $secondary;
    margin-bottom: 8px;
  }
}
