.ct-form {
  max-width: 400px;
  padding: 20px 30px;
  background: url("../images/advice-form-bg.jpg") center/cover no-repeat;
  border-radius: 10px;

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    margin-bottom: 16px;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 35px;
    padding: 0 30px;
    border-radius: 4px;
    background-color: $white;
    border: 0;
    color: $secondary;

    &:hover {
      color: $secondary;
      text-decoration: none;
    }
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-control {
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    border: 0;
  }

  input.form-control {
    height: 30px;
  }
}

.contact {
  & + & {
    margin-top: 49px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, #fff, transparent);
      position: absolute;
      left: 0;
      top: -25px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__item {
    font-size: 14px;
    line-height: 24px;
    padding-left: 35px;
    position: relative;

    & + & {
      margin-top: 10px;
    }

    .fa {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $white;
      position: absolute;
      top: 0;
      left: 0;
      color: $secondary;
    }
  }
}
