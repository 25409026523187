.pack {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include shadow("xs");

  &__frame {
    @include frame("rect", 70%);
    @include frame("zoom");
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 30px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    font-size: 24px;
    font-weight: 700;
    color: $danger;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__btn {
    display: block;
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $warning;
    line-height: 43px;
    border: 1px solid $warning;
    border-radius: 4px;
    padding: 0 8px;
    text-align: center;
    @include shadow("xs");
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: $warning;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &--primary {
      border-color: $primary;
      background-color: $primary;
      color: $white;

      &:hover {
        color: $white;
        background-color: darken($primary, 5%);
      }
    }
  }

  @media (max-width: 767px) {
    &__body {
      padding: 15px;
    }

    &__title {
      margin-bottom: 10px;
    }

    &__price {
      font-size: 20px;
    }

    &__btns {
      margin-top: 10px;
    }
  }
}

.event {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame("rect", 75%);
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 100%;
    padding: 20px;
    background-color: rgba(#166ab8, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  &__time {
    font-size: 22px;
    font-weight: 700;
    color: $white;
    margin-bottom: 20px;
  }

  &__calendar {
    border-right: 1px solid $white;
    padding-right: 18px;
    margin-right: 18px;
  }

  &__date {
    font-size: 30px;
    line-height: 40px;
    color: $yellow;
  }

  &__icon {
    display: flex;
    height: 40px;
    align-items: center;
  }

  &__title {
    @include max-line(3);
    font-size: 14px;
    font-weight: 700;
    color: $white;
    margin-bottom: 10px;
  }

  &__addr {
    margin-top: auto;
    color: $white;
    padding-left: 20px;
    position: relative;

    .fa {
      position: absolute;
      top: 2px;
      left: 0;
      color: $yellow;
    }
  }

  &__btn {
    display: inline-block;
    font-size: 16px;
    line-height: 40px;
    padding: 0 20px;
    min-width: 150px;
    text-align: center;
    color: #333;
    font-weight: 700;
    background-color: $yellow;

    &:hover {
      color: #333;
      text-decoration: none;
      background-color: darken($yellow, 10%);
    }
  }

  @media (max-width: 575px) {
    &__content {
      position: relative;
      width: 100%;
    }
  }
}

.event-2 {
  align-items: center;

  &__frame {
    @include frame("rect", 56.25%);
    width: 380px;
    margin-left: 20px;
  }

  &__time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 145px;
    height: 145px;
    border: 3px solid $yellow;
    margin-right: 20px;
    color: $yellow;
    font-weight: 700;
  }

  &__date {
    font-size: 48px;
  }

  &__month {
    font-size: 16px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  &__addr {
    margin-bottom: 14px;

    .fa {
      color: $yellow;
    }
  }

  &__desc {
    @include max-line(3);
  }

  @media (max-width: 1199px) {
    &__frame {
      width: 300px;
    }

    &__title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__addr {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;

    &::after {
      content: "";
      display: block;
      width: 100%;
      order: -1;
    }

    &__frame {
      width: 100%;
      order: -12;
      margin: 0 0 20px;
    }
  }

  @media (max-width: 767px) {
    &__time {
      width: 120px;
      height: 120px;
    }

    &__date {
      font-size: 30px;
    }

    &__month {
      font-size: 14px;
    }

    &__title {
      font-size: 14px;
    }

    &__addr {
      font-size: 12px;
    }

    &__desc {
      font-size: 12px;
    }
  }
}

.video {
  &__wrapper {
    display: block;
    position: relative;
    width: 225px;
    margin-right: 20px;
    z-index: 1;

    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      transition: 0.3s;
    }

    &:hover::before {
      background-color: rgba(#000, 0.3);
    }
  }

  &__time {
    font-size: 14px;
    line-height: 20px;
    padding: 0 6px;
    border-radius: 4px;
    background-color: rgba(#000, 0.8);
    color: $white;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $white;
    color: $white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    font-size: 12px;
    padding-left: 2px;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__title {
    font-size: 16px;
    color: #a50034;
    margin-bottom: 16px;
  }

  &__desc {
    @include max-line(3);
  }

  @media (max-width: 575px) {
    &__wrapper {
      width: 150px;
      margin-right: 15px;
    }

    &__title {
      font-size: 14px;
      margin-bottom: 4px;
    }

    &__desc {
      font-size: 12px;
    }
  }
}

.faq {
  & + & {
    margin-top: 10px;
  }

  &__header {
    align-items: center;
    background-color: $white;
    padding: 10px 20px;
    color: #333;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    img {
      height: 30px;
      margin-right: 20px;
    }
  }

  &:first-child &__header {
    border-radius: 4px 4px 0 0;
  }

  &__icon {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $secondary;
    color: $secondary;
    transition: 0.3s;
  }

  &__header.active &__icon {
    transform: rotate(90deg);
  }

  &__body {
    display: none;
    background-color: $white;
    padding: 20px;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid $border-color;
  }
}

.news {
  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
    width: 280px;
    margin-right: 30px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  &__desc {
    @include max-line(3);
    margin-top: 16px;
  }

  &__comment {
    display: inline-flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 12px;
    margin-left: 8px;
    position: relative;
    color: $white;
    background-color: $secondary;

    &::before {
      content: "";
      border: 3px solid transparent;
      border-color: $secondary transparent transparent $secondary;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    &__frame {
      width: 250px;
      margin-right: 20px;
    }

    &__title {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__desc {
      margin-top: 10px;
    }
  }

  @media (max-width: 575px) {
    &__frame {
      width: 45%;
      margin-right: 15px;
    }

    &__title {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__desc {
      display: none;
    }
  }
}

.news-2 {
  &__frame {
    @include frame("rect", 80%);
    @include frame("zoom");
    width: 100px;
    margin-right: 10px;
  }

  &__title {
    font-size: 14px;
    margin-top: -4px;
    margin-bottom: 4px;
  }

  &__time {
    display: flex;
    align-items: center;
    color: #666;
    margin-bottom: -2px;
  }
}

.news-3 {
  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
    margin-bottom: 15px;
  }

  &__title {
    @include max-line(3);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__info {
    color: #666;

    & > span {
      padding: 3px 0;
    }
  }

  @media (max-width: 575px) {
    &__frame {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 13px;
    }

    &__info {
      font-size: 12px;

      img {
        height: 16px;
      }
    }
  }
}

.test {
  &__wrapper {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  &__frame {
    @include frame("square");
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 15px;
    text-align: center;
    color: $white;
    border-radius: 10px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 5px solid #000;
      background-color: $secondary;
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0 0;
  }

  &__icon {
    height: 30px;
    position: absolute;
    bottom: 25px;
    right: 25px;
  }

  &__text-1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
  }

  &__text-2 {
    font-size: 14px;
    font-weight: 700;
  }

  &__text-3 {
    font-size: 14px;
    text-transform: uppercase;
  }

  &__text-4 {
    margin-top: 12px;
    font-size: 30px;
  }

  @media (max-width: 1199px) {
    &__overlay {
      &::before {
        border-width: 3px;
      }
    }

    &__icon {
      height: 24px;
      right: 12px;
      bottom: 12px;
    }

    &__text-4 {
      font-size: 26px;
      margin-top: 8px;
    }
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 14px;
      margin-top: 8px;
    }

    &__text-1 {
      font-size: 30px;
    }

    &__text-2,
    &__text-3 {
      font-size: 13px;
    }

    &__text-4 {
      font-size: 20px;
      margin-top: 4px;
    }
  }
}

.card-1 {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame("square");

    img {
      transition: 0.4s;
    }
  }

  &:hover &__frame img {
    transform: scale(1.05) rotate(2deg);
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 50px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    color: $white;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.25;
    color: $white;
    margin-bottom: 16px;
  }

  &__desc {
    @include max-line(3);
    font-size: 16px;
    color: $white;
    margin-bottom: 16px;
  }

  &__btn {
    display: inline-block;
    font-size: 16px;
    line-height: 50px;
    padding: 0 45px;
    border-radius: 25px;
    background-color: $secondary;
    color: $white;
    text-transform: uppercase;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($secondary, 5%);
    }

    &--white {
      background-color: $white;
      color: $secondary;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 575px) {
    &__overlay {
      padding: 20px;
    }

    &__subtitle {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    &__desc {
      font-size: 13px;
      margin-bottom: 10px;
    }

    &__btn {
      font-size: 14px;
      line-height: 35px;
      border-radius: 18px;
      padding: 0 24px;
    }
  }
}

.card-2 {
  position: relative;
  z-index: 1;
  background-color: #25acde;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  &--white-bg {
    background-color: #f9f9f9;
  }

  &__bg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.4s;
  }

  &:hover &__bg {
    transform: scale(1.05) rotate(2deg);
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &:hover {
      text-decoration: none;
    }
  }

  &__icon {
    display: flex;
    height: 30px;
    align-items: center;
    margin-bottom: 15px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__title {
    @include max-line(4);
    font-size: 25px;
    font-weight: 700;
    color: $white;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
    margin-bottom: 16px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #333;
  }

  &__location {
    margin-top: 16px;
    color: #333;
    text-align: center;

    img {
      height: 19px;
    }
  }

  @media (max-width: 1199px) {
    &__overlay {
      padding: 20px;
    }

    &__title {
      font-size: 20px;
    }

    &__logo {
      height: 80px;
    }

    &__location {
      margin-top: 16px;

      img {
        height: 16px;
      }
    }
  }

  @media (max-width: 575px) {
    &__overlay {
      padding: 12px;
    }

    &__title {
      font-size: 16px;
    }

    &__icon {
      height: 24px;
      margin-bottom: 8px;
    }

    &__logo {
      height: 50px;
      margin-bottom: 8px;
    }

    &__name {
      font-size: 13px;
    }

    &__location {
      font-size: 12px;
      margin-top: 8px;

      img {
        height: 10px;
      }
    }
  }
}

.campus {
  &__frame {
    @include frame("rect", 75%);
    @include frame("zoom");
  }

  &__title {
    font-size: 14px;
    text-align: center;
    margin: 16px 0 0;
    text-align: center;
  }
}

.recruitment {
  &__frame {
    @include frame("rect", 80%);
    width: 110px;
    margin-right: 16px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  &__stars {
    &::before {
      color: $secondary !important;
    }
  }
}

.pros {
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    margin-bottom: 16px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;
  }

  &__desc {
    text-align: center;
  }
}

.brochure {
  display: block;
  color: #000;
  border: 1px solid #707070;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  padding: 16px 20px 16px 20px;

  &:hover {
    color: $secondary;
    text-decoration: none;
    border-color: $secondary;
  }

  .fa {
    margin-right: 20px;
  }

  @media (max-width: 1800px) {
    padding: 8px 16px;
    font-size: 16px;

    .fa {
      margin-right: 16px;
    }
  }
}
