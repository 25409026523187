$phoneRingColor: #e73a35;

.phone-ring {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: $phoneRingColor;
  position: fixed;
  z-index: 50;
  bottom: 50px;
  left: 50px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: "";
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid $phoneRingColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: "";
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba($phoneRingColor, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba($phoneRingColor, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
  }

  &__text {
    display: inline-block;
    background-color: rgba($phoneRingColor, 0.7);
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px 0 60px;
    color: $white;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
  }

  &:hover &__text {
    color: $phoneRingColor;
    background-color: rgba(#fff, 0.7);
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

.btn-movetop {
  display: none;
  position: fixed;
  z-index: $zindex-sticky;
  bottom: 30px;
  right: 30px;
  background-color: rgba($primary, 0.8);
  box-shadow: 0 2px 10px rgba(#000, 0.15);
  color: $white;
  transition: 0.3s;

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: $primary;
    box-shadow: 0 1px 4px rgba(#000, 0.35);
  }

  @media (max-width: 767px) {
    right: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

.sticky-btns {
  margin-top: auto;
  padding: 0 20px;

  &__item {
    display: flex;
    align-items: center;
    color: $white;
    font-size: 20px;
    text-transform: uppercase;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__icon {
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 1399px) {
    &__item {
      font-size: 16px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  @media (max-width: 1199px) {
    padding: 10px 20px;
  }
}
