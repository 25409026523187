.acc-page {
  &__header {
    display: none;
  }

  &__main {
    padding: 30px 0 30px 330px;
  }

  &__aside {
    width: 100%;
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-bottom: 1px solid $border-color;
      background-color: $white;
    }

    &__toggle {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    &__main {
      padding: 30px 0;
    }
  }
}

.as-profile {
  border-bottom: 1px solid $border-color;
  margin-bottom: 10px;
  padding: 20px;

  &__avatar {
    @include frame("circle");
    width: 115px;
    margin: 0 auto;
  }

  &__name {
    font-size: 16px;
    font-style: italic;
    text-align: center;
    color: $white;
    margin-top: 10px;
  }

  &__time {
    text-align: center;
    color: $white;
  }
}

.upload-form {
  border-top: 1px solid $border-color;
  padding-top: 16px;

  &__btn {
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 40px;
    line-height: 48px;
  }

  .form-control {
    font-size: 14px;
    line-height: 20px;
    padding: 9px 15px;
  }

  input.form-control {
    height: 40px;
  }

  .custom-select {
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 15px;
  }

  .col-form-label {
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    .col-form-label {
      text-align: left;
    }
  }
}
