.counting {
  padding: 45px 0;
  background: url("../images/counting-bg.jpg") center/cover no-repeat;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83px;
    margin-bottom: 10px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__number {
    font-size: 50px;
    color: $white;
    text-align: center;
  }

  &__label {
    font-size: 30px;
    color: $primary;
    text-align: center;
  }

  @media (max-width: 1199px) {
    &__icon {
      height: 60px;
    }

    &__number {
      font-size: 40px;
    }

    &__label {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    &__icon {
      height: 50px;
    }

    &__number {
      font-size: 30px;
    }

    &__label {
      font-size: 16px;
    }
  }
}

.support {
  &__frame {
    @include frame("rect", 66.66%);
    height: 100%;

    &::after {
      content: "";
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      background: linear-gradient(to right, transparent, $white);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &__title {
    font-size: 37px;
    font-weight: 700;
    text-align: center;
    color: $secondary;
    margin-bottom: 30px;
    letter-spacing: 4px;
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;

    a {
      color: #333;

      &:hover {
        color: $secondary;
        text-decoration: none;
      }
    }
  }

  &__btns {
    display: flex;
  }

  &__btn {
    display: block;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    background-color: $primary;
    flex: 1;
    padding: 13px;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 5%);
    }

    &:first-child {
      border-radius: 25px 0 0 25px;
    }

    &:last-child {
      border-radius: 0 25px 25px 0;
    }

    &--secondary {
      background-color: $secondary;

      &:hover {
        background-color: darken($secondary, 5%);
      }
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__social-btn {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $secondary;
    color: $white;
    font-size: 20px;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($secondary, 5%);
    }

    &:not(:last-child) {
      margin-right: 45px;
    }
  }

  .form-group {
    margin-bottom: 30px;
  }

  .form-control {
    height: 50px;
    border: 1px solid #5d5d5d;
    border-radius: 25px;
    padding: 0 30px;

    &::placeholder {
      color: #5d5d5d;
    }
  }

  @media (max-width: 991px) {
    &__frame {
      &::after {
        width: 100%;
        height: 60%;
        top: auto;
        bottom: 0;
        background: linear-gradient(to bottom, transparent, $white);
      }
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &__nav {
      margin-bottom: 20px;
    }

    &__social {
      margin-top: 20px;
    }

    .form-group {
      margin-bottom: 20px;
    }
  }
}
