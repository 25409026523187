body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Roboto", Arial, Helvetica, apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

a,
button,
input,
textarea,
select {
  &:focus {
    outline: 0;
  }
}

p:last-child {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    display: inline;
    background: 0;
    font: inherit;
    margin: 0;
    padding: 0;

    &,
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      color: $primary;
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &__content {
    flex-grow: 1;
  }
}
