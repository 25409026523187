.btn {
  &-default {
    border-color: $border-color;
    background-color: transparent;

    &:hover {
      border-color: $primary;
      color: $primary;
    }
  }

  &-rounded {
    border-radius: 18px;
  }

  &-shine {
    @include btn("shine");
  }

  &-fill {
    @include btn("fill");
  }

  &-fill.btn-primary {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $primary;
    }
  }

  &-fill.btn-danger {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $danger;
    }
  }

  &-fill.btn-outline-primary {
    &::before {
      background-color: $primary;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &-fill.btn-outline-danger {
    &::before {
      background-color: $danger;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &-neon-orange {
    @include btn-neon("orange");
  }

  &-neon-green {
    @include btn-neon("green");
  }

  &-neon-purple {
    @include btn-neon("purple");
  }

  &-neon-blue {
    @include btn-neon("blue");
  }

  &-neon-dark {
    @include btn-neon("dark");
  }
}

$rounded-list: 8 10 18 20 22 24 25 26 28 30;
@each $item in $font-size-list {
  .rounded-#{$item} {
    border-radius: #{$item}px;
  }
}

.button {
  display: inline-block;
  min-width: 200px;
  text-align: center;
  border: 0;
  font-size: 18px;
  line-height: 2;
  padding: 15px 20px;
  color: $white;
  text-transform: uppercase;
  background-color: $primary;

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: darken($primary, 5%);
  }

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 20px;
    min-width: 150px;
  }
}
