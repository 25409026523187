.list {
  &__item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
  }
}

.list-2 {
  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $border-color;
    }
  }
}

.list-3 {
  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $border-color;
    }
  }
}
