.intro-slider {
  background-color: $light;
  padding: 40px 0 90px;
  position: relative;
  z-index: 1;

  &__item {
    background-color: $light;
  }

  &__inner {
    max-width: 1170px;
    position: relative;
    margin: 0 auto;
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 36px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      margin: 0 10px;
      background-color: #d5d5d5;
      border-radius: 50%;
      opacity: 1;

      &:focus {
        outline: 0;
      }

      &-active {
        background: 0;
        border: 2px solid #707070;
      }
    }
  }

  &__prev,
  &__next {
    display: flex;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    img {
      height: 24px;
    }
  }

  &__frame {
    @include frame("rect", 56.25%);
    width: 75%;
    margin-left: auto;
  }

  &__content {
    width: 27%;
    min-width: 300px;
    padding: 30px;
    @include shadow("");
    background-color: $white;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;

    a {
      color: #333;
    }

    a:hover {
      color: #333;
      text-decoration: none;
    }
  }

  &__info {
    color: #666;
    margin-bottom: 16px;
  }

  &__desc {
    @include max-line(5);
  }

  @media (max-width: 991px) {
    &__frame {
      width: 100%;
    }

    &__content {
      position: static;
      width: 100%;
      min-width: unset;
      box-shadow: unset;
      transform: translateY(0);
    }
  }

  @media (max-width: 767px) {
    &__prev,
    &__next {
      img {
        height: 20px;
      }
    }

    &__pagination {
      margin: 0 5px;

      .swiper-pagination-bullet {
        margin: 0 5px;
      }
    }
  }

  @media (min-width: 992px) {
    &__content {
      opacity: 0;
      transform: translateY(-250%) scale(0);
      transition: 0.8s;
    }

    &__frame {
      margin-left: 0;
      transition: 0.8s;
    }

    &__item:nth-child(even) &__frame {
      margin-left: 25%;
    }

    &__item:nth-child(even) &__content {
      left: auto;
      right: 0;
    }

    .swiper-slide-active &__frame {
      margin-left: 25%;
    }

    &__item.swiper-slide-active:nth-child(even) &__frame {
      margin-left: 0;
    }

    .swiper-slide-active &__content {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }
  }
}

.avatar-slider {
  max-width: 290px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding-bottom: 36px;

  &__pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #d6d5d5;
      margin: 0 5px;
      opacity: 1;

      &:focus {
        outline: 0;
      }

      &-active {
        background-color: $green-lighter;
      }
    }
  }

  &__frame {
    @include frame("circle");
    opacity: 0.5;
  }

  .swiper-slide-active &__frame {
    opacity: 1;
  }
}

.logo-slider {
  position: relative;
  height: 180px;
  background-color: $white;

  &__container {
    width: calc(100% - 60px);
    position: absolute;
    top: 55px;
    left: 30px;
  }

  &__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 1599px) {
    height: 100px;

    &__container {
      width: calc(100% - 40px);
      top: 30px;
      left: 20px;
    }

    &__frame {
      height: 40px;
    }
  }

  @media (max-width: 991px) {
    height: 60px;

    &__container {
      top: 15px;
      left: 15px;
      width: calc(100% - 30px);
    }

    &__frame {
      height: 30px;
    }
  }
}
