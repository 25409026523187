.navbar {
  &__wrapper {
    display: flex;
    width: 300px;
    height: 100vh;
    flex-direction: column;
    position: fixed;
    z-index: $zindex-fixed;
    top: 0;
    left: -100%;
    background-color: $white;
    transition: 0.3s;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid $border-color;
  }

  &__title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 1;
  }

  &__close {
    padding: 0 2px;
    background-color: transparent;
    border: 0;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;

    &:before {
      content: "\f104\f104\f104";
      font-family: "fontawesome";
    }
  }

  &__body {
    padding: 10px 0;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__backdrop {
    display: block;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -100%;
    transition: left 0s 0.3s, opacity 0.3s;
  }

  &__footer {
    padding: 20px;
    margin-top: auto;
  }

  &.is-show &__wrapper {
    left: 0;
  }

  &.is-show &__backdrop {
    left: 0;
    opacity: 0.5;
    transition: left 0s, opacity 0.3s;
  }

  &--custom &__wrapper {
    background: url("../images/ml-page-bg.jpg") center/cover no-repeat;
  }

  &--custom &__body {
    display: flex;
    flex-direction: column;
  }

  &--account &__wrapper {
    background: url("../images/ml-page-bg.jpg") center/cover no-repeat;
  }

  &--account &__body {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1200px) {
    &__toggle {
      display: none !important;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) {
    display: block;
    position: static;

    &__wrapper {
      display: block;
      width: auto;
      height: auto;
      position: static;
      background: 0;
      z-index: 0;
      top: auto;
      left: auto;
      transition: unset;
    }

    &__body {
      padding: 0;
      overflow: visible;
    }

    &__backdrop,
    &__header {
      display: none;
    }

    &--custom {
      height: 100%;
    }

    &--custom &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      background: 0;
    }

    &--custom &__body {
      flex-grow: 1;
    }

    &--custom &__header {
      display: flex;
      height: 101px;
      border-bottom: 0;

      .logo {
        display: none;
      }

      .js-navbar-toggle {
        display: none;
      }
    }

    &--custom &__footer {
      border-top: 0;
    }

    &__toggle {
      display: block;
      margin-left: 30px;
    }

    &--account {
      width: 300px;
      position: relative;
    }

    &--account &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      overflow: auto;
      position: absolute;
      top: 0;
      left: 0;
    }

    &--account &__body {
      flex-grow: 1;
    }

    &--account &__header {
      display: flex;
      justify-content: center;
      align-items: center;

      .js-navbar-toggle {
        display: none;
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    &__footer {
      padding: 0 20px 10px;
    }
  }
}

.navbar-toggle {
  display: inline-flex;
  width: 32px;
  height: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
  background: 0;
  line-height: 1;
  font-size: 24px;

  span {
    display: block;
    width: 28px;
    height: 2px;
    background-color: #333;
    margin: 3px 0;
    border-radius: 2px;
    transition: 0.4s;
  }

  &--float-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &--float-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &--white {
    span {
      background-color: $white;
    }
  }

  &.active {
    span {
      &:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }
}
