.get-info-form {
  margin-bottom: 30px;

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    border: 2px solid $white;
    height: 50px;
    padding: 30px;
    color: $white;
    background: 0;

    &::placeholder {
      color: $white;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0;

    .form-control {
      height: 48px;
      border-width: 1px;
      padding: 0 20px;
    }
  }
}
